import React, { useState } from "react";

import { Menu } from "../../../components/menu";
import { Quiz } from "../../../components/quiz";
import { APP, LANG } from "../../../constants";

export const QuizPage: React.FC = () => {
  const [app, setApp] = useState(APP.SCRUM_PSM_1);
  const [lang, setLang] = useState(LANG.DE);
  const [isScoreVisible, setIsScoreVisible] = useState(true);

  return (
    <Menu setApp={setApp} setLang={setLang} isScoreVisible={isScoreVisible} setIsScoreVisible={setIsScoreVisible}>
      <Quiz app={app} lang={lang} isScoreVisible={isScoreVisible} />
    </Menu>
  );
};
