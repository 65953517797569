import React from "react";
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom";

import { ProtectedRoute } from "./components/router/protectedRoute";
import { Login } from "./pages/login";
import { QuizPage } from "./pages/quiz";

export const App: React.FC = () => {
  const r = createBrowserRouter(
    createRoutesFromElements(
      <Route path={"/"}>
        <Route
          index
          element={
            <ProtectedRoute>
              <QuizPage />
            </ProtectedRoute>
          }
        />

        <Route path={"login"} element={<Login />} />
      </Route>
    )
  );

  return <RouterProvider router={r} />;
};
