import React from "react";

import { Button, Center, Heading, Stack, useToast } from "@chakra-ui/react";
import { TokenResponse, useGoogleLogin } from "@react-oauth/google";
import { AiFillGoogleCircle } from "react-icons/ai";

import { useAuth } from "../../../provider/authentication";

export const Login: React.FC = () => {
  const imgPath = process.env.PUBLIC_URL + "/ts_mint.png";
  const { doLogin } = useAuth();
  const toast = useToast();
  const login = useGoogleLogin({
    onSuccess: codeResponse => onSuccess(codeResponse),
    onError: error => onError(error)
  });

  function onSuccess(response: TokenResponse) {
    doLogin(response);
    toast({
      title: "Login erfolgreich!",
      status: "success",
      duration: 9000,
      isClosable: true
    });
  }

  // eslint-disable-next-line
  function onError(error: any) {
    toast({
      title: "Login nicht erfolgreich!",
      description: error,
      status: "error",
      duration: 9000,
      isClosable: true
    });
  }

  return (
    <Center h={"100%"}>
      <Stack>
        <img src={imgPath} alt={"Logo"} style={{ width: "200px", marginBottom: "15px" }} />
        <Heading style={{ margin: "20px auto" }}>Quiz App</Heading>
        <br />
        <Button onClick={() => login()}>
          <AiFillGoogleCircle fontSize={"30px"} />
        </Button>
      </Stack>
    </Center>
  );
};
