import React, { useEffect, useState } from "react";

import {
  Box,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Link,
  Popover,
  PopoverTrigger,
  useColorModeValue,
  useDisclosure,
  useToast
} from "@chakra-ui/react";

import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";

import { APP, LANG } from "../constants";
import { useAuth } from "../provider/authentication";

interface NavItem {
  label: string;
  subLabel?: string;
  children?: Array<NavItem>;
  href?: string;
  app?: APP;
  setApp?: Function;
}

interface MenuProps extends React.PropsWithChildren {
  setApp: Function;
  setLang: Function;
  isScoreVisible: boolean;
  setIsScoreVisible: Function;
}

interface NavProps extends React.PropsWithChildren {
  setApp: Function;
  setLang: Function;
}

const NAV_ITEMS: Array<NavItem> = [
  {
    label: "AWS: Cloud Practitioner",
    app: APP.AWS_CP
  },
  {
    label: "AWS: Solutions Architect",
    app: APP.AWS_SA
  },
  {
    label: "LPIC: Linux Essentials",
    app: APP.LPIC_LINUX_ESSENTIALS
  },
  {
    label: "Scrum: PSM-I",
    app: APP.SCRUM_PSM_1
  }
];

export const Menu: React.FC<MenuProps> = props => {
  const { setApp, setLang, isScoreVisible, setIsScoreVisible } = props;
  const { isOpen, onToggle } = useDisclosure();
  const { doLogout } = useAuth();
  const toast = useToast();

  const [isActiveButtonDE, setIsActiveButtonDE] = useState(false);
  const [isActiveButtonEN, setIsActiveButtonEN] = useState(false);

  useEffect(() => {
    setLangToDE();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Set the language to DE.
   *
   */
  function setLangToDE() {
    setLang(LANG.DE);
    setIsActiveButtonDE(true);
    setIsActiveButtonEN(false);
  }

  /**
   * Set the language to EN.
   *
   */
  function setLangToEN() {
    setLang(LANG.EN);
    setIsActiveButtonDE(false);
    setIsActiveButtonEN(true);
  }

  /**
   * Logout handler.
   *
   */
  function onLogout() {
    doLogout();
    toast({
      title: "Logout erfolgreich!",
      status: "success",
      duration: 9000,
      isClosable: true
    });
  }

  return (
    <Box>
      <Flex
        bg={useColorModeValue("white", "gray.900")}
        color={useColorModeValue("gray.600", "white")}
        minH={"60px"}
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        borderStyle={"solid"}
        borderColor={useColorModeValue("gray.200", "gray.900")}
        align={"center"}
      >
        <Flex flex={{ base: 1, md: "auto" }} ml={{ base: -2 }} display={{ base: "flex", md: "none" }}>
          <IconButton
            onClick={onToggle}
            icon={isOpen ? <CloseIcon w={3} h={3} /> : <HamburgerIcon w={5} h={5} />}
            variant={"ghost"}
            aria-label={"Toggle Navigation"}
          />
        </Flex>
        <Flex flex={{ base: 1 }} justify={{ base: "center", md: "start" }}>
          <img src={process.env.PUBLIC_URL + "/ts_mint.png"} width={"32px"} alt={"logo"} />
          <Flex display={{ base: "none", md: "flex" }} ml={10}>
            <DesktopNav setApp={setApp} setLang={setLang} />
          </Flex>
        </Flex>

        <Stack flex={{ base: 1, md: 0 }} justify={"flex-end"} direction={"row"} spacing={6}>
          <Button fontSize={"sm"} fontWeight={400} variant={"outline"} onClick={() => setIsScoreVisible(!isScoreVisible)}>
            {isScoreVisible ? "Verstecke " : "Zeige "} Score
          </Button>
          <Button
            fontSize={"sm"}
            fontWeight={400}
            variant={"outline"}
            isActive={isActiveButtonDE}
            isDisabled={isActiveButtonDE}
            onClick={setLangToDE}
          >
            DE
          </Button>
          <Button
            fontSize={"sm"}
            fontWeight={600}
            variant={"outline"}
            isActive={isActiveButtonEN}
            isDisabled={isActiveButtonEN}
            onClick={setLangToEN}
          >
            EN
          </Button>

          <Button
            as={"a"}
            fontSize={"sm"}
            fontWeight={600}
            href={"mailto:djordje@techstarter.de?subject=Report Techstarter Quiz&body=Describe your issue"}
          >
            Report
          </Button>

          <Button onClick={() => onLogout()} fontSize={"sm"} fontWeight={600} variant={"ghost"}>
            Logout
          </Button>
        </Stack>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav setApp={setApp} setLang={setLang} />
      </Collapse>

      <Box>{props.children}</Box>
    </Box>
  );
};

const DesktopNav: React.FC<NavProps> = props => {
  const { setApp } = props;
  const linkColor = useColorModeValue("gray.600", "gray.200");
  const linkHoverColor = useColorModeValue("gray.800", "white");

  return (
    <Stack direction={"row"} spacing={4}>
      {NAV_ITEMS.map(navItem => (
        <Box key={navItem.label}>
          <Popover trigger={"hover"} placement={"bottom-start"}>
            <PopoverTrigger>
              <Link
                p={2}
                fontSize={"sm"}
                fontWeight={500}
                color={linkColor}
                onClick={() => setApp(navItem.app)}
                _hover={{
                  textDecoration: "none",
                  color: linkHoverColor
                }}
              >
                {navItem.label}
              </Link>
            </PopoverTrigger>
          </Popover>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav: React.FC<NavProps> = props => {
  const { setApp } = props;

  return (
    <Stack bg={useColorModeValue("white", "gray.800")} p={4} display={{ md: "none" }}>
      {NAV_ITEMS.map(navItem => (
        <MobileNavItem key={navItem.label} setApp={setApp} {...navItem} />
      ))}
    </Stack>
  );
};

const MobileNavItem = ({ label, setApp, app }: NavItem) => {
  return (
    <Stack spacing={4} onClick={() => setApp!(app)}>
      <Flex
        py={2}
        as={Link}
        justify={"space-between"}
        align={"center"}
        _hover={{
          textDecoration: "none"
        }}
      >
        <Text fontWeight={600} color={useColorModeValue("gray.600", "gray.200")}>
          {label}
        </Text>
      </Flex>
    </Stack>
  );
};
