import React, { useEffect, useState } from "react";

import { Card, Center, Divider, Heading, Stack, Text } from "@chakra-ui/react";

import GaugeChart from "react-gauge-chart";

import { IQuestion, Question } from "../../models/question";
import { APP, LANG } from "../../../constants";
import { QuestionCard } from "./questionCard";
import { useAuth } from "../../../provider/authentication";

interface QuizProps {
  app: APP;
  lang: LANG;
  isScoreVisible: boolean;
}

export const Quiz: React.FC<QuizProps> = props => {
  const { app, lang, isScoreVisible } = props;
  const { user } = useAuth();
  const [question, setQuestion] = useState<Question>();
  const [questionChecked, setQuestionChecked] = useState(false);
  const [questionsDoneCorrect, setQuestionsDoneCorrect] = useState<Array<Number>>([]);
  const [countQuestionsTotal, setCountQuestionsTotal] = useState(0);
  const [countQuestionsCorrect, setCountQuestionsCorrect] = useState(0);

  /**
   * Get a question in the beginning.
   *
   */
  useEffect(() => {
    getQuestion();

    setCountQuestionsTotal(0);
    setCountQuestionsCorrect(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [app]);

  /**
   * Get the same question again after language chaged.
   *
   */
  useEffect(() => {
    if (question && question.id) {
      getQuestion(question.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lang]);

  /**
   * Increase the number of countQuestionsTotal.
   *
   */
  function increaseCountTotal() {
    setCountQuestionsTotal(countQuestionsTotal + 1);
  }

  /**
   * Increase the number of countQuestionsCorrect.
   *
   */
  function increaseCountCorrect() {
    setCountQuestionsCorrect(countQuestionsCorrect + 1);

    const tmpQuestionsDoneCorrect = questionsDoneCorrect;
    tmpQuestionsDoneCorrect.push(Number(question?.id));
    setQuestionsDoneCorrect(tmpQuestionsDoneCorrect);
  }

  /**
   * Function to render the name of the app.
   *
   */
  function renderAppName() {
    switch (app) {
      case APP.AWS_CP:
        return "AWS - Cloud Practitioner";
      case APP.AWS_SA:
        return "AWS - Solutions Architect";
      case APP.SCRUM_PSM_1:
        return "Scrum Master - PSM I";
      case APP.LPIC_LINUX_ESSENTIALS:
        return "LPIC - Linux Essentials";
    }
  }

  /**
   * Fetch the question from the backend.
   *
   */
  async function getQuestion(questionId: string | null = null) {
    let url = `https://r7t5pezwewrxu4gypsosmvpxte0ofjud.lambda-url.eu-central-1.on.aws/`;
    url += `?app=${app}`;
    url += `&lang=${lang}`;
    url += `&ignoreIds=[${questionsDoneCorrect}]`;
    url += `&token=${user?.accessToken}`;

    if (questionId !== undefined && questionId !== null && questionId !== "") {
      url += `&id=${questionId}`;
    }

    const res = await fetch(url);
    const resJson: IQuestion = await res.json();
    setQuestion(new Question(resJson));
  }

  return (
    <Center mt={3}>
      <Stack gap={3}>
        <Center>
          <Heading>{renderAppName()}</Heading>
        </Center>
        <Divider />

        <QuestionCard
          question={question}
          getQuestion={getQuestion}
          increaseCountTotal={increaseCountTotal}
          increaseCountCorrect={increaseCountCorrect}
          questionChecked={questionChecked}
          setQuestionChecked={setQuestionChecked}
        />

        <Divider />

        {countQuestionsTotal && isScoreVisible && (
          <Card paddingTop={3} w={{ base: "auto", lg: "3xl" }}>
            <Stack>
              <Center>
                <Heading as={"h3"} size={"lg"}>
                  Score ({countQuestionsCorrect} / {countQuestionsTotal})
                </Heading>
              </Center>

              <Center>
                <GaugeChart
                  nrOfLevels={20}
                  percent={countQuestionsCorrect / countQuestionsTotal}
                  style={{ maxWidth: "800px" }}
                  animate={false}
                />
              </Center>
            </Stack>
          </Card>
        )}

        {questionChecked && (
          <Card paddingTop={3} w={{ base: "auto", lg: "3xl" }}>
            <Stack>
              <Center>
                <Heading as={"h3"} size={"lg"}>
                  Erklärung
                </Heading>
              </Center>
            </Stack>

            <Text padding={5} dangerouslySetInnerHTML={{ __html: question?.explanation || "" }}></Text>
          </Card>
        )}
      </Stack>
    </Center>
  );
};
