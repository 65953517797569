import React, { createContext, useState } from "react";
import axios from "axios";
import { TokenResponse, googleLogout } from "@react-oauth/google";

import { User } from "../models/user";
import { browserStorage, deleteBrowserData } from "../storages/localStorage";

interface IAuthContextStates {
  user: null | User;
  isAuthenticated: boolean;
  doLogin: Function;
  doLogout: Function;
}

/**
 * Create the context.
 *
 */
const AuthContext = createContext<IAuthContextStates>({
  user: null,
  isAuthenticated: false,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  doLogin: () => {},
  doLogout: () => {}
});

export const AuthProvider: React.FC<React.PropsWithChildren> = props => {
  const [user, setUser] = useState<User | null>(browserStorage.getUser());
  const [isAuthenticated, setIsAuthenticated] = useState<boolean>(Boolean(browserStorage.getAccessToken()));

  /**
   * Function that handles the actual login process.
   *
   */
  async function doLogin(response: TokenResponse) {
    const url = `https://www.googleapis.com/oauth2/v3/userinfo`;
    const headers = { Authorization: `Bearer ${response.access_token}`, Accept: "application/json" };
    const res = await axios.get(url, { headers: headers });
    if (res.status === 200) {
      handleOnLoginSuccess(res.data, response.access_token);
      return true;
    } else {
      return false;
    }
  }

  /**
   * Function that handles the actual logout process.
   *
   */
  function doLogout() {
    deleteBrowserData();
    googleLogout();
    setTimeout(() => (window.location.href = "/"));
  }

  /**
   * Handles the success login process.
   * Save the access and refresh token.
   * Create a user model.
   *
   */
  function handleOnLoginSuccess(data: Record<string, unknown>, access_token: string) {
    const u = new User({ ...data, accessToken: access_token });

    browserStorage.setAccessToken(access_token);
    browserStorage.setUser(u);

    setUser(u);
    setIsAuthenticated(true);
    setTimeout(() => (window.location.href = "/"), 2_000);
  }

  return <AuthContext.Provider value={{ user, isAuthenticated, doLogin, doLogout }}>{props.children}</AuthContext.Provider>;
};

export function useAuth(): IAuthContextStates {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error("useAuth must be used within a AuthContext.");
  }

  return context;
}
