export enum QuestionType {
  Multi = "multi",
  Single = "single",
}

export interface IQuestion {
  id: string;
  count: number;
  question: string;
  answers: Array<string>;
  type: QuestionType;
  correct: Array<number>;
  explanation: string;
}

export class Question {
  id: string;
  count: number;
  question: string;
  answers: Array<string>;
  type: QuestionType;
  correct: Array<number>;
  explanation: string;

  constructor(data: IQuestion) {
    this.id = data.id;
    this.count = data.count;
    this.question = data.question;
    this.answers = data.answers;
    this.type = data.type;
    this.correct = data.correct;
    this.explanation = data.explanation;
  }
}
