export interface IUser {
  email: string;
  firstName: string;
  lastName: string;
  accessToken: string;
}
export class User implements IUser {
  email: string;
  firstName: string;
  lastName: string;
  accessToken: string;

  constructor(data: Record<string, unknown>) {
    this.email = String(data.email);
    this.firstName = String(data.given_name);
    this.lastName = String(data.family_name);
    this.accessToken = String(data.accessToken);
  }
}
