import React from "react";

import { Navigate, useLocation } from "react-router-dom";

import { useAuth } from "../../provider/authentication";

export const ProtectedRoute: React.FC<React.PropsWithChildren> = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const location = useLocation();

  if (!isAuthenticated) {
    return <Navigate to={"/login"} replace state={{ from: location }} />;
  } else {
    return <React.Fragment>{children}</React.Fragment>;
  }
};
