import React from "react";
import ReactDOM from "react-dom/client";

import { ChakraProvider } from "@chakra-ui/react";

import { GoogleOAuthProvider } from "@react-oauth/google";

import { App } from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "./theme";
import { GoogleClientId } from "./constants";
import { AuthProvider } from "./provider/authentication";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <React.StrictMode>
    <GoogleOAuthProvider clientId={GoogleClientId}>
      <AuthProvider>
        <ChakraProvider theme={theme}>
          <App />
        </ChakraProvider>
      </AuthProvider>
    </GoogleOAuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
