import React, { useState } from "react";

import {
  Button,
  ButtonGroup,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  CheckboxGroup,
  Divider,
  Input,
  InputGroup,
  InputLeftAddon,
  Radio,
  RadioGroup,
  Stack,
  Text
} from "@chakra-ui/react";

import { Question, QuestionType } from "../../models/question";
import { compareArrays } from "../../../helpers";

interface QuestionCardProps {
  question?: Question;
  getQuestion: Function;
  questionChecked: boolean;
  setQuestionChecked: Function;
  increaseCountTotal: Function;
  increaseCountCorrect: Function;
}

export const QuestionCard: React.FC<QuestionCardProps> = props => {
  const { question, getQuestion, increaseCountTotal, increaseCountCorrect, questionChecked, setQuestionChecked } = props;
  const [userInput, setUserInput] = useState<Array<number>>([]);
  const [questionId, setQuestionId] = useState<string | null>(null);

  /**
   * Renders the answers for single choice questions.
   *
   */
  function renderAnswersSingle() {
    return (
      <RadioGroup value={String(userInput[0])}>
        <Stack spacing={4}>
          {question?.answers.map((answer, index) => (
            <Radio
              key={index + 1}
              size={"md"}
              value={String(index + 1)}
              onChange={evt => setUserInput([Number(evt.target.value)])}
              isDisabled={questionChecked}
            >
              <Text color={questionChecked ? (index + 1 === question.correct[0] ? "green.400" : "red.400") : ""}>{answer}</Text>
            </Radio>
          ))}
        </Stack>
      </RadioGroup>
    );
  }

  /**
   * Renders the answers for multiple choice questions.
   *
   */
  function renderAnswersMulti() {
    return (
      <CheckboxGroup value={userInput} onChange={arr => setUserInput(arr.map(a => Number(a)))}>
        <Stack spacing={4}>
          {question?.answers.map((answer, index) => (
            <Checkbox key={index + 1} size={"md"} value={index + 1} isDisabled={questionChecked}>
              <Text color={questionChecked ? (question.correct.includes(index + 1) ? "green.400" : "red.400") : ""}>{answer}</Text>
            </Checkbox>
          ))}
        </Stack>
      </CheckboxGroup>
    );
  }

  /**
   * Check the answers.
   *
   */
  function checkAnswers() {
    if (!questionChecked) {
      const correct = compareArrays(userInput, question!.correct);

      if (correct) {
        increaseCountCorrect();
      }

      increaseCountTotal();
      setQuestionChecked(true);
    }
  }

  /**
   * Get a new question from backend and reset some values.
   *
   */
  function getNewQuestion() {
    getQuestion(questionId);
    setQuestionId(null);
    setQuestionChecked(false);
    setUserInput([]);
  }

  if (!question) {
    return null;
  }

  return (
    <Card w={{ base: "auto", lg: "3xl" }}>
      <CardBody>
        <Stack spacing={3}>
          <strong><Text dangerouslySetInnerHTML={{ __html: question.question }}></Text></strong>
          
          <Divider />
          
          {question.type === QuestionType.Single ? renderAnswersSingle() : renderAnswersMulti()}
        </Stack>
      </CardBody>

      <Divider />

      <CardFooter>
        <Stack>
          <InputGroup>
            <InputLeftAddon>Current ID</InputLeftAddon>
            <Input value={`${question.id} / ${question.count}`} disabled={true} width={"120px"} />
          </InputGroup>
        </Stack>
        <Stack flexDirection={"row"} justifyContent={"right"} alignItems={"center"} width={"full"}>
          <ButtonGroup>
            <InputGroup>
              <InputLeftAddon>ID</InputLeftAddon>
              <Input
                value={questionId || undefined}
                onChange={evt => setQuestionId(evt.target.value)}
                width={"100px"}
                isDisabled={questionChecked}
              />
            </InputGroup>

            <Button
              variant={"solid"}
              colorScheme="blue"
              marginTop={0}
              onClick={questionChecked || questionId ? getNewQuestion : checkAnswers}
              w={"120px"}
              isDisabled={!questionId && compareArrays(userInput, [])}
            >
              {questionChecked || questionId ? "Nächste" : "Prüfen"}
            </Button>
          </ButtonGroup>
        </Stack>
      </CardFooter>
    </Card>
  );
};
