export const GoogleClientId = "806286347112-6q6lg6otgparr4fajh22gcbfm3soghdb.apps.googleusercontent.com";

export enum APP {
  AWS_CP = "aws-cp",
  AWS_SA = "aws-sa",
  LPIC_LINUX_ESSENTIALS = "lpic-linux-essentials",
  SCRUM_PSM_1 = "scrum-psm1"
}

export enum LANG {
  DE = "de",
  EN = "en"
}
