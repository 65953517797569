  /**
   * Compare two arrays.
   *
   * @param a
   * @param b
   */
  export function compareArrays(a: Array<number>, b: Array<number>) {
    const sortedA: Array<number> = a.sort((n1, n2) => n1 - n2);
    const sortedB: Array<number> = b.sort((n1, n2) => n1 - n2);

    if (sortedA.length !== sortedB.length) {
      return false;
    } else {
      // Comparing each element of your array
      for (let i = 0; i < sortedA.length; i++) {
        if (sortedA[i] !== sortedB[i]) {
          return false;
        } 
      }
      return true;
    }
  }